.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.Hero {
  width: 1318px;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.container02 {
  width: 1199px;
  height: 791px;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.Card {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-twelveunits);
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: var(--dl-radius-radius-radius1);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  backdrop-filter: saturate(200%) blur(30px);
}
.text {
  color: #c30000;
  width: 574px;
  align-self: flex-start;
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text001 {
  color: var(--dl-color-gray-700);
  align-self: flex-start;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text003 {
  color: #ffffff;
  font-size: 1rem;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
}
.container03 {
  display: flex;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.container04 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.container05 {
  display: flex;
  position: relative;
}
.link {
  color: var(--dl-color-gray-white);
  outline: none;
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  transition: all .15s ease-in;
  padding-top: var(--dl-space-space-triplequarterunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: 1.875rem;
  padding-right: var(--dl-space-space-unitandahalfunit);
  padding-bottom: var(--dl-space-space-triplequarterunit);
  text-decoration: none;
  background-color: #545454;
}
.link:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.link01 {
  display: contents;
}
.component {
  text-decoration: none;
}
.image {
  top: 1177px;
  left: -1px;
  width: 50%;
  height: 80vh;
  opacity: 0.9;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-right-radius: 10rem;
}
.image01 {
  top: 0px;
  left: auto;
  right: 0px;
  width: 49%;
  bottom: 0px;
  height: 80vh;
  opacity: 1;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}
.container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: 8rem;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.container08 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.text005 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text006 {
  color: rgb(203, 12, 159);
  white-space: normal;
  text-decoration: none;
}
.text007 {
  color: var(--dl-color-gray-700);
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text008 {
  display: inline;
  font-weight: 400;
  white-space: normal;
}
.container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unitandahalfunit);
  margin-right: var(--dl-space-space-unitandahalfunit);
  flex-direction: row;
}
.container10 {
  flex: 0 0 auto;
  width: 50%;
  height: 218px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.text009 {
  flex: 0 0 auto;
  color: #ffffff;
  width: 460px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  border-color: #c30000;
  border-width: 1px;
  border-radius: 0.25rem;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.text010 {
  display: inline;
  font-weight: 400;
  white-space: normal;
}
.text014 {
  font-weight: 400;
}
.text018 {
  font-weight: 400;
}
.container11 {
  width: 50%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: var(--dl-space-space-triplequarterunit);
  padding-right: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}
.container12 {
  width: 90%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.text019 {
  color: #ffffff;
  height: 196px;
}
.text020 {
  font-weight: 700;
  white-space: normal;
}
.text021 {
  color: #ffffff;
  height: 652px;
}
.text022 {
  font-weight: 400;
  white-space: normal;
}
.text028 {
  font-weight: 400;
  white-space: normal;
}
.Gallery {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  margin-top: 16rem;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
  grid-template-columns: 1fr 1fr 1fr;
}
.GalleryCard {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.Image02 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text101 {
  width: 396px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text102 {
  color: #ffffff;
  align-self: flex-start;
  text-align: left;
}
.text103 {
  text-align: left;
}
.GalleryCard01 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard01:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image03 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text104 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text105 {
  color: #ffffff;
  align-self: flex-start;
  text-align: left;
}
.text106 {
  text-align: justify;
}
.text107 {
  text-align: justify;
}
.text108 {
  text-align: justify;
}
.GalleryCard02 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard02:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image04 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text110 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text111 {
  color: #ffffff;
  align-self: flex-start;
  text-align: left;
}
.GalleryCard03 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard03:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image05 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text112 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text113 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard04 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard04:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image06 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text114 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text115 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard05 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard05:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image07 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text116 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text117 {
  color: #ffffff;
  align-self: flex-start;
}
.Gallery1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  padding-top: var(--dl-space-space-unitandahalfunit);
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
  grid-template-columns: 1fr 1fr 1fr;
}
.GalleryCard06 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard06:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.Image08 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text120 {
  width: 360px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text121 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard07 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard07:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image09 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text122 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text123 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard08 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard08:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image10 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text124 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text125 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard09 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard09:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image11 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text126 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text127 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard10 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard10:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image12 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text128 {
  width: 356px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text129 {
  color: #ffffff;
  align-self: flex-start;
}
.GalleryCard11 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5rem;
  justify-content: center;
}
.GalleryCard11:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image13 {
  width: 100%;
  height: 224px;
  object-fit: cover;
}
.text130 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text131 {
  color: #ffffff;
  align-self: flex-start;
}
.Gallery2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1400px;
  padding-top: var(--dl-space-space-unitandahalfunit);
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
  grid-template-columns: 1fr 1fr 1fr;
}
.GalleryCard12 {
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.GalleryCard12:hover {
  box-shadow: 0px 3px 15px 0px #ff0000;
}
.image14 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text132 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text133 {
  color: #ffffff;
  align-self: flex-start;
}
.container13 {
  flex: 0 0 auto;
  width: 246px;
  height: 122px;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  justify-content: center;
}
.text134 {
  width: 285px;
  height: 101px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unitandahalfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Features {
  width: 100%;
  display: flex;
  max-width: 1400px;
  margin-top: 16rem;
  padding-top: 8rem;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: 16rem;
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 8rem;
}
.text135 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 48px;
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container14 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  position: relative;
  grid-template-columns: 1fr 1fr;
}
.FeatureCard {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.FeatureCard:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image15 {
  width: 79px;
  height: 64px;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.container15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text136 {
  color: #000000;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.text137 {
  color: #000000;
}
.FeatureCard1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.FeatureCard1:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image16 {
  width: 106px;
  height: 69px;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.container16 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text138 {
  color: #000000;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.text139 {
  color: #000000;
}
.FeatureCard2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.FeatureCard2:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image17 {
  width: 62px;
  height: 63px;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.container17 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text140 {
  color: #000000;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.text141 {
  color: #000000;
}
.FeatureCard3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.FeatureCard3:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image18 {
  width: 108px;
  height: 65px;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.container18 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text142 {
  color: #000000;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.text143 {
  color: #000000;
}
.FeatureCard4 {
  left: 314px;
  width: 600px;
  bottom: -198px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.FeatureCard4:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image19 {
  width: 73px;
  height: 75px;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.container19 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text144 {
  color: #000000;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.text145 {
  color: #000000;
}
.Steps {
  width: 100%;
  display: flex;
  max-width: 1400px;
  margin-top: 16rem;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: 48px;
  margin-bottom: 16rem;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.text148 {
  color: #ffffff;
  font-size: 3rem;
  margin-bottom: 32px;
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text150 {
  color: var(--dl-color-gray-700);
  font-size: 1.15rem;
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.container20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.Step {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.Line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container22 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.Line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container23 {
  width: 225px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.text151 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text153 {
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
}
.Step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.Line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container25 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.icon02 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.Line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container26 {
  width: 373px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.text157 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text159 {
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
}
.container27 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.container28 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container29 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.Line4 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container30 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.icon04 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.Line5 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container31 {
  width: 308px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.text164 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text166 {
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
}
.Step3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.Line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container33 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.icon07 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.Line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.container34 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.text168 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text170 {
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
}
.Services {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  margin-top: 16rem;
  align-items: center;
  margin-bottom: 16rem;
  flex-direction: column;
}
.text172 {
  font-size: 3rem;
  margin-bottom: 32px;
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text175 {
  color: var(--dl-color-gray-700);
  width: 70%;
  font-size: 1.15rem;
  text-align: center;
}
.text176 {
  color: var(--dl-color-gray-700);
  font-size: 1.15rem;
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.container35 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.FeatureCard5 {
  width: 100%;
  display: flex;
  padding: 32px;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.FeatureCard5:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image20 {
  width: 100px;
  object-fit: cover;
}
.text177 {
  color: #000000;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text178 {
  color: #000000;
  text-align: center;
}
.FeatureCard6 {
  width: 100%;
  display: flex;
  padding: 32px;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.FeatureCard6:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image21 {
  width: 100px;
  object-fit: cover;
}
.text179 {
  color: #000000;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text180 {
  color: #000000;
  text-align: center;
}
.FeatureCard7 {
  width: 100%;
  display: flex;
  padding: 32px;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.FeatureCard7:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image22 {
  width: 100px;
  object-fit: cover;
}
.text181 {
  color: #000000;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text182 {
  color: #000000;
  text-align: center;
}
.FeatureCard8 {
  width: 100%;
  display: flex;
  padding: 32px;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.FeatureCard8:hover {
  box-shadow: 0px 5px 10px 0px #ff0000;
}
.image23 {
  width: 100px;
  object-fit: cover;
}
.text183 {
  color: #000000;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.text184 {
  color: #000000;
  text-align: center;
}
.Banner {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  margin-top: 16rem;
  align-items: center;
  justify-content: space-between;
}
.container36 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-between;
}
.text185 {
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text187 {
  color: #ffffff;
  margin-right: 48px;
}
.container37 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.textinput {
  color: #000000;
  width: 316px;
  font-size: 0.75rem;
  border-color: var(--dl-color-gray-500);
  border-radius: 4px;
}
.button {
  color: #000000;
  font-size: 0.75rem;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-500);
}
.button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-black);
}
.image24 {
  width: 216px;
  height: 275px;
  object-fit: contain;
  flex-shrink: 0;
  object-position: center;
}
.link02 {
  display: contents;
}
.icon09 {
  fill: #ffffff;
  width: 76px;
  height: 76px;
  margin-top: var(--dl-space-space-twelveunits);
  transition: 0.05s;
  text-decoration: none;
}
.icon09:active {
  opacity: 1;
  border-color: #000000;
  border-style: inset;
  border-width: var(--dl-size-size-unit);
  border-radius: var(--dl-radius-radius-round);
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
}
.Header {
  top: 0;
  width: 100%;
  height: 191px;
  display: flex;
  position: fixed;
  align-items: center;
  padding-top: 28px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 28px;
  justify-content: space-between;
}
.image25 {
  width: 98px;
}
.image26 {
  left: 36px;
  width: 105px;
  bottom: -6px;
  height: 32px;
  margin: auto;
  opacity: 1;
  position: absolute;
  object-fit: cover;
}
.Nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.link03 {
  color: #ffffff;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  text-decoration: none;
}
.link03:hover {
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.link04 {
  color: #ffffff;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  margin-left: 32px;
  text-decoration: none;
}
.link04:hover {
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.link05 {
  color: #ffffff;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  margin-left: 32px;
  text-decoration: none;
}
.link05:hover {
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.link06 {
  color: #ffffff;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  margin-left: 32px;
  text-decoration: none;
}
.link06:hover {
  background-image: linear-gradient(310deg,#ff0000,#6F0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon11 {
  width: 16px;
  height: 16px;
  display: none;
}
.Footer {
  width: 100%;
  bottom: 0;
  height: 236px;
  display: flex;
  position: relative;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.Container38 {
  width: 276px;
  height: 89px;
  display: flex;
  max-width: 1400px;
  margin-top: 7rem;
  align-items: flex-start;
  flex-direction: column;
}
.image27 {
  width: 127px;
  height: 30px;
  margin-top: var(--dl-space-space-unit);
}
.text189 {
  margin-top: 10px;
}
.text192 {
  text-align: left;
}
.LinksContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.Container39 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.Container40 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.text194 {
  font-weight: 700;
  margin-bottom: 24px;
}
.link07 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.link08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.link09 {
  text-decoration: none;
}
.Container41 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.text195 {
  font-weight: 700;
  margin-bottom: 24px;
}
.text196 {
  margin-bottom: var(--dl-space-space-unit);
}
.text197 {
  margin-bottom: var(--dl-space-space-unit);
}
.Container42 {
  width: 161px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.container43 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.text199 {
  font-weight: 700;
  margin-bottom: 24px;
}
.text200 {
  margin-bottom: var(--dl-space-space-unit);
}
.text201 {
  margin-bottom: var(--dl-space-space-unit);
}
.container44 {
  top: 329px;
  left: 0px;
  width: 100%;
  height: 361px;
  display: none;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.container45 {
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}
.icon13 {
  width: 24px;
  height: 24px;
}
.container46 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.text203 {
  font-size: 3rem;
  text-align: center;
}
.text204 {
  max-width: 1400px;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}
.icon15 {
  width: 24px;
  height: 24px;
}
.container47 {
  flex: 0 0 auto;
  width: auto;
  height: 42px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.icon17 {
  width: 24px;
  height: 24px;
}
.icon19 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .container01 {
    display: none;
  }
  .Hero {
    display: none;
  }
  .container02 {
    display: none;
  }
  .Card {
    width: 100%;
    align-self: center;
  }
  .image {
    display: none;
  }
  .image01 {
    display: none;
  }
  .container06 {
    display: none;
  }
  .container07 {
    display: none;
  }
  .container08 {
    display: none;
  }
  .text007 {
    text-align: center;
  }
  .text009 {
    width: 50%;
  }
  .text021 {
    max-width: 1000px;
  }
  .Gallery {
    grid-template-columns: 1fr 1fr;
  }
  .Gallery1 {
    grid-template-columns: 1fr 1fr;
  }
  .Gallery2 {
    grid-template-columns: 1fr 1fr;
  }
  .text148 {
    text-align: center;
  }
  .text150 {
    text-align: center;
  }
  .text172 {
    text-align: center;
  }
  .text175 {
    text-align: center;
  }
  .text176 {
    text-align: center;
  }
  .container35 {
    grid-template-columns: 1fr 1fr;
  }
  .Banner {
    flex-direction: column;
  }
  .container36 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: 48px;
  }
  .text187 {
    text-align: center;
    margin-left: 48px;
  }
  .container37 {
    justify-content: center;
  }
  .image24 {
    width: 288px;
    height: 288px;
  }
  .Header {
    justify-content: center;
  }
  .image26 {
    display: none;
  }
  .Nav {
    display: none;
  }
  .icon11 {
    display: none;
  }
  .Footer {
    display: none;
  }
  .Container38 {
    align-items: center;
  }
  .LinksContainer {
    width: auto;
    margin-top: 32px;
    flex-direction: row;
  }
  .Container39 {
    margin-right: 0px;
  }
  .container44 {
    display: flex;
  }
  .container45 {
    top: 0px;
    left: 0px;
    width: 100%;
    margin: auto;
    display: flex;
    position: absolute;
    align-items: stretch;
    justify-content: space-between;
  }
  .icon13 {
    fill: #ffffff;
    width: 42px;
    height: 42px;
    align-self: center;
    margin-bottom: var(--dl-space-space-unitandahalfunit);
  }
  .text203 {
    color: #ffffff;
  }
  .text204 {
    color: #ffffff;
    max-width: 100%;
  }
  .icon15 {
    fill: #ffffff;
    width: 42px;
    height: 42px;
    align-self: center;
  }
  .icon17 {
    fill: #ffffff;
    width: 42px;
    height: 42px;
    margin-right: var(--dl-space-space-twelveunits);
  }
  .icon19 {
    fill: #ffffff;
    width: 42px;
    height: 42px;
    margin-left: var(--dl-space-space-twelveunits);
  }
}
@media(max-width: 767px) {
  .Hero {
    width: -moz-fit-content;
  }
  .container02 {
    width: -moz-fit-content;
  }
  .Card {
    align-items: center;
  }
  .image {
    display: none;
  }
  .image01 {
    display: none;
  }
  .container07 {
    display: none;
  }
  .container09 {
    align-items: center;
    flex-direction: column;
  }
  .container10 {
    width: 80%;
  }
  .text009 {
    width: 70%;
    align-self: center;
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .container11 {
    width: 80%;
  }
  .Gallery {
    padding-left: 32px;
    padding-right: 32px;
  }
  .GalleryCard {
    flex-direction: column;
  }
  .GalleryCard01 {
    flex-direction: column;
  }
  .GalleryCard02 {
    flex-direction: column;
  }
  .GalleryCard03 {
    flex-direction: column;
  }
  .GalleryCard04 {
    flex-direction: column;
  }
  .GalleryCard05 {
    flex-direction: column;
  }
  .Gallery1 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .GalleryCard06 {
    flex-direction: column;
  }
  .GalleryCard07 {
    flex-direction: column;
  }
  .GalleryCard08 {
    flex-direction: column;
  }
  .GalleryCard09 {
    flex-direction: column;
  }
  .GalleryCard10 {
    flex-direction: column;
  }
  .GalleryCard11 {
    flex-direction: column;
  }
  .Gallery2 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .GalleryCard12 {
    flex-direction: column;
  }
  .Features {
    padding-left: 32px;
    padding-right: 32px;
  }
  .container14 {
    grid-template-columns: 1fr;
  }
  .FeatureCard {
    flex-direction: row;
  }
  .container15 {
    flex-direction: column;
  }
  .FeatureCard1 {
    flex-direction: row;
  }
  .container16 {
    flex-direction: column;
  }
  .FeatureCard2 {
    flex-direction: row;
  }
  .container17 {
    flex-direction: column;
  }
  .FeatureCard3 {
    flex-direction: row;
  }
  .container18 {
    flex-direction: column;
  }
  .FeatureCard4 {
    flex-direction: row;
  }
  .container19 {
    flex-direction: column;
  }
  .Steps {
    padding-left: 32px;
    padding-right: 32px;
  }
  .container20 {
    align-items: flex-start;
    padding-left: 80px;
    flex-direction: column;
  }
  .Step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .container21 {
    width: 48px;
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .Line1 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .container23 {
    width: 100%;
    align-items: flex-start;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .text151 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .text153 {
    text-align: left;
  }
  .Step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .container24 {
    width: 48px;
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .Line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .Line3 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .container26 {
    width: 100%;
    align-items: flex-start;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .text157 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .text159 {
    text-align: left;
  }
  .Step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .container29 {
    width: 48px;
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .Line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .Line5 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .container31 {
    width: 100%;
    align-items: flex-start;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .text164 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .text166 {
    text-align: left;
  }
  .Step3 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .container32 {
    width: 48px;
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .Line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .Line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .container34 {
    width: 100%;
    align-items: flex-start;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .text168 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .text170 {
    text-align: left;
  }
  .Services {
    padding-left: 32px;
    padding-right: 32px;
  }
  .text172 {
    text-align: center;
  }
  .Banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .text187 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .Nav {
    display: none;
  }
  .icon11 {
    display: flex;
  }
  .Footer {
    display: none;
  }
  .image27 {
    margin-bottom: 24px;
  }
  .LinksContainer {
    width: auto;
    margin-top: 32px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .Container39 {
    width: 100%;
    justify-content: space-between;
  }
  .Container41 {
    margin-right: 0px;
  }
  .Container42 {
    margin-top: 32px;
  }
  .container43 {
    margin-right: var(--dl-space-space-unit);
  }
  .text204 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .Hero {
    width: -moz-fit-content;
  }
  .container02 {
    width: -moz-fit-content;
  }
  .Card {
    padding: var(--dl-space-space-unit);
  }
  .container03 {
    align-items: center;
    flex-direction: column;
  }
  .container04 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .container07 {
    display: none;
  }
  .text021 {
    max-width: 1000px;
  }
  .Gallery {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .Image02 {
    height: 10rem;
  }
  .image03 {
    height: 10rem;
  }
  .image04 {
    height: 10rem;
  }
  .image05 {
    height: 10rem;
  }
  .image06 {
    height: 10rem;
  }
  .image07 {
    height: 10rem;
  }
  .Gallery1 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .Image08 {
    height: 10rem;
  }
  .image09 {
    height: 10rem;
  }
  .image10 {
    height: 10rem;
  }
  .image11 {
    height: 10rem;
  }
  .image12 {
    height: 10rem;
  }
  .image13 {
    height: 10rem;
  }
  .Gallery2 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .image14 {
    height: 10rem;
  }
  .Features {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .container15 {
    flex-direction: column;
  }
  .container16 {
    flex-direction: column;
  }
  .container17 {
    flex-direction: column;
  }
  .container18 {
    flex-direction: column;
  }
  .container19 {
    flex-direction: column;
  }
  .Steps {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .container20 {
    padding-left: 0px;
  }
  .container23 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .text151 {
    margin-left: 0px;
    margin-right: 32px;
  }
  .container26 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .text157 {
    margin-left: 0px;
    margin-right: 32px;
  }
  .container31 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .text164 {
    margin-left: 0px;
    margin-right: 32px;
  }
  .container34 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .text168 {
    margin-left: 0px;
    margin-right: 32px;
  }
  .Services {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .container35 {
    grid-template-columns: 1fr;
  }
  .Banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .container37 {
    align-items: center;
    flex-direction: column;
  }
  .button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .image24 {
    width: 192px;
    height: 192px;
  }
  .Footer {
    padding: var(--dl-space-space-unit);
  }
  .image27 {
    margin-bottom: 0px;
  }
  .LinksContainer {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .Container39 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .Container40 {
    margin-right: var(--dl-space-space-unit);
  }
  .Container41 {
    align-self: stretch;
  }
}
